@import "parameters";


.App-header {
    background-image:  url('../images/background.png') !important;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

    @media (max-width: $md-breakpoint) {
        background-position: left;
    }
}

.header {

    h1 {
        font-size: 5rem;
        text-transform: uppercase;
        font-weight: bold;
        text-align: left;
        display: flex;
        text-wrap: nowrap;

        @media (max-width: $md-breakpoint) {
            font-size: 4rem;
            text-align: center;
            text-wrap: wrap;
        }
    }

    .heading {
        align-self: center;
    }

    .scroll-container {
        position: absolute;
        margin-top: 5rem;
        top: 55%;
        left: 0;
        right: 0;


        @media (max-width: $md-breakpoint) {
            top: 10%;
        }
    }


    .scroll {
        font-size: 1.5rem;
        padding: 0.3rem 0;
        text-transform: uppercase;
        letter-spacing: .3rem;
    }

    .rfm-marquee {
        padding-right: 1rem;
    }

    .image-container {
        z-index: 10;
    }

    .header-image {
        max-height: 45rem;
        padding: 0 1rem;
    }

}



.hide {
    display: none;
}