@import "parameters";

.image-left {
    display: grid;
}

.image-right {
    display: grid;
    margin-bottom: 2rem;
}

.illustration-left {
    z-index: 10;
    margin: 1rem 0 0 1rem;
    border-radius: 4rem 9rem;

    @media (max-width: $md-breakpoint) {
        border-radius: 2rem 6rem;
        padding: 1rem;
        margin: 0;
    }
}

.illustration-right {
    z-index: 10;
    margin: 1rem 1rem 0 0;
    border-radius: 9rem 4rem; 

    @media (max-width: $md-breakpoint) {
        border-radius: 2rem 6rem;
        padding: 1rem;
        margin: 0;
    }
}

.blob-left {
    position: absolute;
    width: 75%;
    left: 4rem;
    background-color: $pink;
    padding: 5rem 0 5rem 0;
    border-radius: 0 5rem 5rem 0;
    z-index: 1;
    margin-left: -10%;
    align-self: center;

    @media (max-width: $md-breakpoint) {
        margin-left: -9%;
        width: 87%;
    }

    @media (max-width: 570px) {
        margin-left: -15%;
        width: 100%;
        padding: 3rem 0 5rem 0;
    }
}

.blob-right {
    position: absolute;
    width: 75%;
    right: 4rem;
    background-color: $pink;
    padding: 5rem 0rem 5rem 0;
    border-radius: 5rem 0 0 5rem;
    z-index: 1;
    margin-right: -10%;
    align-self: center;

    @media (max-width: $md-breakpoint) {
        margin-right: -9%;
        width: 87%;
    }

    @media (max-width: 570px) {
        margin-right: -15%;
        width: 100%;
        padding: 3rem 0rem 5rem 0;
    }
}
