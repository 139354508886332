// Colours:
$yellow: #ffcc4d;
$orange: #dc6044;
$orange-hover: #dc5132;
$background-colour: #faf0e8;
$pink: #ffa7df;


// Sizes
$mobile-max-width: 660px;
$md-breakpoint: 768px;