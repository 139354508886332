@import "parameters";

.section {
    background-color: $pink;
    overflow: visible;
}

.slanted {
    background-color: $pink;
    min-height: 7rem;
    margin-top: 2rem;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    overflow: visible;
}

.slanted-down {
    background-color: $pink;
    min-height: 7rem;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    overflow: visible;
}

.menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    


    .menu-item {
        color: black;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-decoration: none;
        display: flex;
        overflow: hidden;
        border-radius: 3rem;
        margin-bottom: 1rem;

        &:hover {
            background-color: $yellow;
            color: black;
        }
    }

    h2 {
        font-size: 4rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .5rem;
        padding: .5rem;

        @media (max-width: $md-breakpoint) {
            font-size: 3rem;
        }


        @media (max-width: $mobile-max-width) {
            font-size: 2rem;
        }
    }
}

.show {
    background-color: $yellow;
    color: black !important;
}