@import "parameters";

.previous-work {
    justify-content: center; 

    
    @media (max-width: $mobile-max-width) {
        padding: 1.5rem;
    }
}

.work-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: .5rem 0;

    background-color: white;
    color: black;

    margin: .5rem;
    padding: 1.5rem .5rem;
    border-radius: 2rem;

    .h3 {
        text-transform: uppercase;
        font-weight: 600;
    }

    p {
        text-transform: uppercase;
    }

    .youtube {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0rem 2rem 0rem;

        &:hover {
            transform: scale(1.5);

            @media (max-width: $mobile-max-width) {
                transform: scale(1.2);
            }
        }
    }

    .youtube iframe {
        width: 100%;
        height: 25vh; 

        @media (max-width: $mobile-max-width) {
            width: 90%;
            height: 21vh;
        }
     }
}

.work-link {
    color: black;
}

.about-content {
    padding: 1rem;
}